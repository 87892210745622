import { Link } from 'gatsby'
import * as React from 'react'
import Article from '../components/article'
import Seo from '../components/seo'

const NotFoundPage = () => {
  return (
    <>
      <Seo
        pageDescription={"Oops... We couldn't find that page"}
        pageTitle={"Oops... We couldn't find that page"}
      />
      <Article>
        <div className='text-center'>
          <h1 className='display-1 text-muted'>Oops...</h1>
          <h2>We couldn't find that page.</h2>
          <p>
            <Link to={'/'}>Back to home</Link>.
          </p>
        </div>
      </Article>
    </>
  )
}

export default NotFoundPage
